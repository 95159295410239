import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import CalendarReducer from '../../calendar/reducers/CalendarReducer';
import UserTokenReducer from '../../scheduler/reducers/UserTokenReducer';

export default history =>
	combineReducers({
		UserTokenReducer,
		CalendarReducer,
		router: connectRouter(history),
	});
