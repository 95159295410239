import { PureComponent } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import withErrorBoundary from './ErrorBoundary';
import Spinner from './Spinner';

const styles = () => ({
	spinner: {
		margin: 'auto',
		position: 'absolute',
		top: 'calc(45% - 32px)',
		left: 'calc(50% - 32px)',
	},
});

@withStyles(styles)
@withErrorBoundary
export default class Preloader extends PureComponent {
	render() {
		const { classes } = this.props;

		return <Spinner size={64} className={classes.spinner} />;
	}
}
