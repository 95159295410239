import { PureComponent } from 'react';

class DMSLoadableErrorBoundary extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch() {
		const wasReloaded = localStorage.getItem('autoreloadOnError');

		if (wasReloaded) {
			localStorage.removeItem('autoreloadOnError');
			return;
		}

		localStorage.setItem('autoreloadOnError', 1);
		window.location.reload(true);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div>
					Looks like you application is outdated or has an error. Please refresh the page:{' '}
					<button type="button" onClick={() => window.location.reload(true)}>
						Reload
					</button>
				</div>
			);
		}

		return this.props.children;
	}
}

export default DMSLoadableErrorBoundary;
