import ReactDOM from 'react-dom';
import DMSLoadable from 'ringlead_utils/components/DMSLoadable/DMSLoadable';

const getUserConfirmation = (message, callback) => {
	const div = document.createElement('div');
	div.id = 'confirmLeave';
	document.body.appendChild(div);

	const callBack = ok => {
		const confirmLeave = document.getElementById('confirmLeave');

		if (confirmLeave) confirmLeave.parentNode.removeChild(confirmLeave);

		callback(ok);
	};

	const UserConfirmation = DMSLoadable({
		loader: () => import('ringlead_utils/components/LeaveHook/UserConfirmation'),
	});

	ReactDOM.render(
		<UserConfirmation message={message} callback={callBack} />,
		document.getElementById('confirmLeave')
	);
};

export default getUserConfirmation;
