import { Route, Switch } from 'react-router';
import DMSLoadable from 'ringlead_utils/components/DMSLoadable/DMSLoadable';

const Setup = DMSLoadable({
	loader: () => import('../../scheduler/components/Setup'),
});

const NylasCallback = DMSLoadable({
	loader: () => import('../../scheduler/components/NylasCallback'),
});

const NotFoundPage = DMSLoadable({
	loader: () => import('ringlead_utils/components/NotFoundPage'),
});

export default (
	<Switch>
	    <Route path="/setup/oauth/callback" component={NylasCallback} />
		<Route path="/setup" component={Setup} />
		<Route path="*" component={NotFoundPage} />
	</Switch>
);
