import { PureComponent } from 'react';
import store from 'ringlead_utils/stores/RootStore';
import size from 'lodash/size';

export const sentryLazyLoad = () => {
	Sentry.onLoad(() => {
		Sentry.init({
			release: BUILD || '-',
		});

		Sentry.configureScope(scope => {
			const user = store.getState().AuthReducer.user;
			if (user && size(user) > 0)
				scope.setUser({
					email: user.email,
					id: user.id,
				});
		});
	});
	Sentry.forceLoad();
};

export const logErrorStack = (ex, context, message = null) => {
	if (typeof Sentry !== 'undefined') {
		if (ex) {
			sentryLazyLoad();

			Sentry.withScope(scope => {
				const user = store.getState().AuthReducer.user;
				if (user && size(user) > 0)
					scope.setUser({
						email: user.email,
						id: user.id,
					});
				if (context) scope.setExtra('extra', context);
				Sentry.captureException(ex);
			});
		}

		if (message) {
			sentryLazyLoad();

			Sentry.withScope(scope => {
				const user = store.getState().AuthReducer.user;
				if (user && size(user) > 0)
					scope.setUser({
						email: user.email,
						id: user.id,
					});
				if (context) scope.setExtra('extra', context);
				Sentry.captureMessage(message);
			});
		}
	}
};

const withErrorBoundary = ComposedComponent =>
	class extends PureComponent {
		constructor(props) {
			super(props);

			this.state = { eventId: null };
		}

		static getDerivedStateFromError() {
			return { hasError: true };
		}

		componentDidCatch(error, errorInfo) {
			if (typeof Sentry !== 'undefined') {
				sentryLazyLoad();

				Sentry.withScope(scope => {
					const user = store.getState().AuthReducer.user;
					if (user && size(user) > 0)
						scope.setUser({
							email: user.email,
							id: user.id,
						});
					scope.setExtras(errorInfo);
					const eventId = Sentry.captureException(error);
					this.setState({ eventId });
				});
			}
		}

		render() {
			if (this.state.hasError) {
				// render fallback UI
				return (
					<p
						onClick={
							typeof Sentry !== 'undefined'
								? () => Sentry.showReportDialog({ eventId: this.state.eventId })
								: null
						}
					>
						We're sorry — something's gone wrong (click here fill out a report).
					</p>
				);
			}

			return <ComposedComponent {...this.props} />;
		}
	};

export default withErrorBoundary;
