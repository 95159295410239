import yellow from '@material-ui/core/colors/yellow';
import { createTheme } from '@material-ui/core/styles';

const DMSPrimaryColors = {
	main: '#006eb9',

	50: '#f4f6fc',
	100: '#b2e3fe',
	200: '#7fd1fc',
	300: '#49bffa',
	400: '#1eb2f9',
	500: '#00a4f7',
	600: '#0096e7',
	700: '#0083d3',
	800: '#006eb9',
	900: '#04529d',
};

const DMSSecondaryColors = {
	main: '#f10091',

	50: '#fbe2f0',
	100: '#f6b7da',
	200: '#f386c1',
	300: '#f14fa7',
	400: '#f10091',
	500: '#f30079',
	600: '#e10076',
	700: '#ca0070',
	800: '#b4006b',
	900: '#8b0063',
};

const theme = createTheme({
	palette: {
		action: {
			hover: DMSPrimaryColors['50'],
		},
		background: {
			default: DMSPrimaryColors['50'],
			helppage: '#fafbfd',
		},
		primary: DMSPrimaryColors,
		secondary: DMSSecondaryColors,
		error: DMSSecondaryColors,
		warning: {
			main: yellow[50],
		},
		prevent: '#322893',
		cleanse: '#0072BF',
		enrich: '#722282',
	},
	nprogress: {
		color: DMSPrimaryColors['900'],
	},
	props: {
		MuiOutlinedInput: {
			margin: 'dense',
		},
	},
	overrides: {
		MuiSelect: {
			select: {
				'&:focus': {
					backgroundColor: 'none',
				},
			},
		},
	},
});

export default theme;
