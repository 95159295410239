import { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import withErrorBoundary from './ErrorBoundary';

@withErrorBoundary
class Spinner extends PureComponent {
	render() {
		return <CircularProgress size={this.props.size} className={this.props.className} />;
	}
}

Spinner.propTypes = {
	className: PropTypes.string,
	size: PropTypes.number,
};

Spinner.defaultProps = {
	size: 40,
};

export default Spinner;
