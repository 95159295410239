import { LOCATION_CHANGE } from 'connected-react-router';

import { AvailableSlotsConstants } from '../../Constants';

const initialState = {
	available_slots: [],
	access_code: null,
	settings: null,
	fetching: true,
	sales_person: null,
	submitter_email: null,
	form_url: null,
	available_dates: [],
	event_created: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return { ...state };

		case AvailableSlotsConstants.AVAILABLE_DATES_GET_REQUEST:
		case AvailableSlotsConstants.CALL_ROUTING_REQUEST:
		case AvailableSlotsConstants.AVAILABLE_SLOTS_GET_REQUEST:
			return { ...state, fetching: true };
		case AvailableSlotsConstants.AVAILABLE_SLOTS_GET_SUCCESS:
			return { ...state, fetching: false, available_slots: action.data.available_time_slots };
		case AvailableSlotsConstants.AVAILABLE_SLOTS_GET_FAIL:
			return { ...state, fetching: false };
		case AvailableSlotsConstants.CALL_ROUTING_REQUEST_SUCCESS:
			localStorage.setItem('rData', JSON.stringify({ ...action.data }));
			return { ...state, ...action.data };
		case AvailableSlotsConstants.CREATE_EVENT_SUCCESS:
			return { ...state, fetching: false, event_created: true };
		case AvailableSlotsConstants.AVAILABLE_DATES_GET_SUCCESS:
			return { ...state, fetching: false, available_dates: action.data.available_dates };
		case AvailableSlotsConstants.AVAILABLE_DATES_GET_FAIL:
			window.location = action.settings.redirect_settings.on_failure_redirect;
			return { ...state, fetching: false, available_dates: [] };
		default:
			return state;
	}
};
