// import { routerMiddleware } from 'connected-react-router';
// import { applyMiddleware, compose, createStore } from 'redux';
// import { createLogger } from 'redux-logger';
// import thunkMiddleware from 'redux-thunk';

// import { logErrorStack } from '../components/ErrorBoundary';
// import browserHistory from '../History';

// import createRootReducer from './RootReducer';

// const loggerMiddleware = createLogger({
// 	duration: true,
// 	timestamp: true,
// 	logErrors: true,
// 	collapsed: true,
// 	diff: SERVER_NAME === SERVER_NAME_DEV || SERVER_NAME === SERVER_NAME_LOCAL,
// });

// const errorMiddleware = () => next => action => {
// 	try {
// 		const result = next(action);

// 		if (
// 			result &&
// 			result.error &&
// 			!result.error.response &&
// 			result.error.message &&
// 			result.error.message !== 'Network Error'
// 		)
// 			logErrorStack(result.error);

// 		return result;
// 	} catch (err) {
// 		logErrorStack(err);
// 		throw err;
// 	}
// };

// const middlewares = [
// 	routerMiddleware(browserHistory),
// 	thunkMiddleware,
// 	loggerMiddleware,
// 	errorMiddleware,
// ];

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(createRootReducer(browserHistory), {}, composeEnhancers(applyMiddleware(...middlewares)));

// export default store;
// export const history = browserHistory;

import { logErrorStack } from '../components/ErrorBoundary';
import { routerMiddleware } from 'connected-react-router';
import get from 'lodash/get';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import browserHistory from '../History';

import createRootReducer from './RootReducer';

const loggerMiddleware = createLogger({
	duration: true,
	timestamp: true,
	logErrors: true,
	collapsed: true,
	diff: false,
});

const errorMiddleware = () => next => action => {
	try {
		const result = next(action);

		if (
			get(result, 'error') &&
			!result.error.response &&
			result.error.message &&
			result.error.message !== 'Network Error'
		) {
			logErrorStack(result.error);
		}

		return result;
	} catch (err) {
		logErrorStack(err);
		throw err;
	}
};

const middlewares = [routerMiddleware(browserHistory), thunkMiddleware, loggerMiddleware, errorMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(createRootReducer(browserHistory), {}, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
export const history = browserHistory;
