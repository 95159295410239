import { LOCATION_CHANGE } from 'connected-react-router';

import { AccessTokenConstants, NylasAuthConstants } from '../../Constants';

const initialState = {
	token_details: {},
	team_member: {},
	fetching: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case AccessTokenConstants.ACCESS_TOKEN_REQUEST:
			return { ...state, fetching: true };

		case AccessTokenConstants.ACCESS_TOKEN_CREATE_SUCCESS:
			const email = action.data.email;
			const token = action.data.token;
			const url_search_params = new URLSearchParams(window.location.search);
            const connection_id = url_search_params.get('con');
			const userData = {
				token: token,
				crm_user: email,
				connection_id: connection_id
			};
			localStorage.setItem('userData', JSON.stringify(userData));
			return { ...state, fetching: false, token_details: action.data };

		case AccessTokenConstants.TEAM_MEMBER_DETAILS_RETRIEVE_SUCCESS:
			return { ...state, fetching: false, team_member: action.data };

		case AccessTokenConstants.TEAM_MEMBER_DETAILS_RETRIEVE_FAIL:
		case AccessTokenConstants.ACCESS_TOKEN_CREATE_FAIL:
			return { ...state, ...initialState, fetching: false };

		case NylasAuthConstants.NYLAS_AUTH_GET_URL_FAIL:
		case NylasAuthConstants.NYLAS_AUTH_GET_URL_SUCCESS:
			return { ...state, fetching: false };
		case AccessTokenConstants.TEAM_MEMBER_DETAILS_UPDATE_SUCCESS:
			return { ...state, fetching: false, team_member: action.data };

		case AccessTokenConstants.TEAM_MEMBER_DETAILS_UPDATE_FAIL:
			return { ...state, fetching: false };

		default:
			return state;
	}
};
