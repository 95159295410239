import { lazy, PureComponent, Suspense } from 'react';
import Preloader from 'ringlead_utils/components/Preloader';

import DMSLoadableErrorBoundary from './DMSLoadableErrorBoundary';

const DMSLoadable = opts => {
	const Component = lazy(opts.loader);

	return class DMSLoadableHook extends PureComponent {
		render() {
			const { ...props } = this.props;

			return (
				<DMSLoadableErrorBoundary>
					<Suspense fallback={<Preloader />}>
						<Component {...props} />
					</Suspense>
				</DMSLoadableErrorBoundary>
			);
		}
	};
};

export default DMSLoadable;
