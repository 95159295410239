import ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { Route, Switch } from 'react-router';
import { MuiThemeProvider } from '@material-ui/core/styles';
import axios from 'axios';
import { ConnectedRouter } from 'connected-react-router';
import CssBaseline from 'ringlead_utils/components/CssBaseline';
import DMSLoadable from 'ringlead_utils/components/DMSLoadable/DMSLoadable';
import PageTitleWrapper from 'ringlead_utils/components/PageContext/PageTitleWrapper';
import Routing from 'ringlead_utils/routes';
import store, { history } from 'ringlead_utils/stores/RootStore';
import theme from 'ringlead_utils/Theme';
import isObject from 'lodash/isObject';
import NProgress from 'nprogress';

import 'regenerator-runtime/runtime';

import CalendarRoutes from './calendar/CalendarRoutes';
import { NotiStackConstants } from './notification/NotiStackConstants';

const Root = DMSLoadable({
	loader: () => import('./Root'),
});

const NotiStack = DMSLoadable({
	loader: () => import('ringlead_utils/components/NotiStack'),
});

const SnackbarProvider = DMSLoadable({
	loader: () => import('ringlead_utils/components/SnackbarProvider'),
});

const ForbiddenPage = DMSLoadable({
	loader: () => import('ringlead_utils/components/ForbiddenPage'),
});

const NotFoundPage = DMSLoadable({
	loader: () => import('./utils/components/NotFoundPage'),
});


let requestsCounter = 0;

axios.interceptors.request.use(config => {
	requestsCounter++;
	NProgress.start();
	return config;
});

const responseFunc = response => {
	if (--requestsCounter === 0) NProgress.done();

	return response;
};

const errorFunc = error => {
	if (--requestsCounter === 0) NProgress.done();

	const response = error.response || {};
	response.config = response.config || {};

	// !response.status - typical situation in oldest browser with cancel flow
	if (axios.isCancel(error) || !response.status) return Promise.reject(error);

	switch (response.status) {
		case 0: // magic error if abruptly cancelling request
		case 400: // typically validation errors
		case 403: // typically forbidden errors
			break;
		case 502: // Bad Gateway
			store.dispatch({
				type: NotiStackConstants.NOTISTACK_CREATE,
				data: {
					key: '502',
					message: 'Sorry, but service is unavailable. Please, try later.',
					variant: 'error',
				},
			});
			break;
		case 401:
			//TODO-need to modify authlogout
			// store.dispatch(AuthLogout());
			break;
		default:
			let err = `URL: ${response.config.url || window.location.pathname}. Status: ${
				response.status
			}. Status text: ${response.statusText}`;

			if (response.data && isObject(response.data)) err += `. Response: ${JSON.stringify(response.data)}`;

			if (response.error && isObject(response.error)) err += `. Error: ${JSON.stringify(response.error)}`;

			store.dispatch({
				type: NotiStackConstants.NOTISTACK_CREATE,
				data: {
					key: err,
					message: err,
					variant: 'error',
				},
			});
			break;
	}

	return Promise.reject(error);
};

axios.interceptors.response.use(responseFunc, errorFunc);

history.listen(() => {
	window.scrollTo(0, 0);
});

ReactDOM.render(
	<Provider store={store} context={ReactReduxContext}>
		<ConnectedRouter history={history} context={ReactReduxContext}>
			<MuiThemeProvider theme={theme}>
				<SnackbarProvider>
					<CssBaseline />
					<NotiStack />
					{window.location.origin === 'https://translate.googleusercontent.com' ? (
						<ForbiddenPage />
					) : (
						<PageTitleWrapper>
							<Switch>
								{CalendarRoutes}
								<Root>
									<Switch>{Routing}</Switch>
								</Root>

								<Route path="*" component={NotFoundPage} />
							</Switch>
						</PageTitleWrapper>
					)}
				</SnackbarProvider>
			</MuiThemeProvider>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('app')
);
