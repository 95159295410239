import { PureComponent } from 'react';
import isEqual from 'lodash/isEqual';

import PageContext from './PageContext';

class PageTitleWrapper extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			breadcrumbs: [],
		};
	}

	render() {
		const { breadcrumbs } = this.state;

		const context = {
			updateState: newState =>
				isEqual(newState.breadcrumbs, this.state.breadcrumbs) ? null : this.setState(newState),
		};

		if (breadcrumbs) context.breadcrumbs = breadcrumbs;

		return <PageContext.Provider value={context}>{this.props.children}</PageContext.Provider>;
	}
}

export default PageTitleWrapper;
