import { PureComponent } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';

const scrollbar_box_shadow = 'inset 0 0 6px rgba(0, 0, 0, 0.3)';
const autofill_box_shadow = '0 0 0 30px white inset !important';

@withStyles(theme => ({
	'@global': {
		'::-webkit-scrollbar': {
			'-webkit-appearance': 'none',
			'-moz-appearance': 'none',
			width: theme.spacing(1),
			height: theme.spacing(1),
		},
		'::-webkit-scrollbar-track': {
			borderRadius: theme.spacing(0.5),
			backgroundColor: 'rgba(100, 100, 100, 0.5)',
			'-webkit-box-shadow': scrollbar_box_shadow,
			'-moz-box-shadow': scrollbar_box_shadow,
			boxShadow: scrollbar_box_shadow,
		},
		'::-webkit-scrollbar-thumb': {
			borderRadius: theme.spacing(1),
			backgroundColor: 'rgba(100, 100, 100, 0.5)',
			'-webkit-box-shadow': scrollbar_box_shadow,
			'-moz-box-shadow': scrollbar_box_shadow,
			boxShadow: scrollbar_box_shadow,
		},
		html: {
			color: theme.palette.text.primary,
			fontFamily: theme.typography.fontFamily,
			fontWeight: theme.typography.body2.fontWeight,
			lineHeight: theme.typography.body2.lineHeight,
			letterSpacing: theme.typography.body2.letterSpacing,
		},
		'input:focus::-webkit-contacts-auto-fill-button': {
			opacity: 0,
		},
		'input::-webkit-clear-button': {
			display: 'none',
		},
		'input:-webkit-autofill': {
			'-webkit-box-shadow': autofill_box_shadow,
		},
		'input:-webkit-autofill:hover': {
			'-webkit-box-shadow': autofill_box_shadow,
		},
		'input:-webkit-autofill:focus': {
			'-webkit-box-shadow': autofill_box_shadow,
		},
		'input:-webkit-autofill:active': {
			'-webkit-box-shadow': autofill_box_shadow,
		},
		p: {
			marginBlockStart: 0,
			marginBlockEnd: 0,
		},
	},
}))
export default class CustomCssBaseLine extends PureComponent {
	render() {
		return <CssBaseline />;
	}
}
