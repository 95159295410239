export const AccessTokenConstants = {
	ACCESS_TOKEN_REQUEST: 'ACCESS_TOKEN_REQUEST',
	ACCESS_TOKEN_CREATE_SUCCESS: 'ACCESS_TOKEN_CREATE_SUCCESS',
	ACCESS_TOKEN_CREATE_FAIL: 'ACCESS_TOKEN_CREATE_FAIL',
	TEAM_MEMBER_DETAILS_RETRIEVE_SUCCESS: 'TEAM_MEMBER_DETAILS_RETRIEVE_SUCCESS',
	TEAM_MEMBER_DETAILS_RETRIEVE_FAIL: 'TEAM_MEMBER_DETAILS_RETRIEVE_FAIL',
	TEAM_MEMBER_DETAILS_UPDATE_SUCCESS: 'TEAM_MEMBER_DETAILS_UPDATE_SUCCESS',
	TEAM_MEMBER_DETAILS_UPDATE_FAIL: 'TEAM_MEMBER_DETAILS_UPDATE_FAIL',
};

export const NylasAuthConstants = {
	NYLAS_AUTH_REQUEST: 'NYLAS_AUTH_REQUEST',
	NYLAS_AUTH_GET_URL_SUCCESS: 'NYLAS_AUTH_GET_URL_SUCCESS',
	NYLAS_AUTH_GET_URL_FAIL: 'NYLAS_AUTH_GET_URL_FAIL',
};

export const AvailableSlotsConstants = {
	AVAILABLE_SLOTS_GET_REQUEST: 'AVAILABLE_GET_REQUEST',
	AVAILABLE_SLOTS_GET_SUCCESS: 'AVAILABLE_SLOTS_GET_SUCCESS',
	AVAILABLE_SLOTS_GET_FAIL: 'AVAILABLE_SLOTS_GET_FAIL',
	CALL_ROUTING_REQUEST: 'CALL_ROUTING_REQUEST,',
	CALL_ROUTING_REQUEST_SUCCESS: 'CALL_ROUTING_REQUEST_SUCCESS',
	CALL_ROUTING_REQUEST_FAIL: 'CALL_ROUTING_REQUEST_FAIL',
	CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
	CREATE_MEETING_DETAIL_SUCCESS: 'CREATE_MEETING_DETAIL_SUCCESS',
	AVAILABLE_DATES_GET_REQUEST: 'AVAILABLE_DATES_GET_REQUEST',
    AVAILABLE_DATES_GET_SUCCESS: 'AVAILABLE_DATES_GET_SUCCESS',
    AVAILABLE_DATES_GET_FAIL: 'AVAILABLE_DATES_GET_FAIL',
    SCHEDULER_SETTINGS_GET_REQUEST: 'SCHEDULER_SETTINGS_GET_REQUEST',
    SCHEDULER_SETTINGS_GET_SUCCESS: 'SCHEDULER_SETTINGS_GET_SUCCESS',
    SCHEDULER_SETTINGS_GET_FAIL: 'SCHEDULER_SETTINGS_GET_FAIL',
};

export const MeetingLinkConstants = {
	MEETING_LINK_REQUEST: 'MEETING_LINK_REQUEST',
	TEAM_MEMBER_DETAILS_UPDATE_SUCCESS: 'TEAM_MEMBER_DETAILS_UPDATE_SUCCESS',
	TEAM_MEMBER_DETAILS_UPDATE_FAIL: 'TEAM_MEMBER_DETAILS_UPDATE_FAIL',
};