import { Route, Switch } from 'react-router';
import DMSLoadable from 'ringlead_utils/components/DMSLoadable/DMSLoadable';

const CalendarInvite = DMSLoadable({
	loader: () => import('./components/CalendarInvite'),
});

const CalendarBlank = DMSLoadable({
	loader: () => import('./components/CalendarBlank'),
});

const NotFoundPage = DMSLoadable({
	loader: () => import('ringlead_utils/components/NotFoundPage'),
});

const CalendarRoutes = () => (
	<Switch>
		<Route exact path="/calendar/:ssid/processing/" component={CalendarBlank} />
		<Route exact path="/calendar/book" component={CalendarInvite} />
		<Route path="*" component={NotFoundPage} />
	</Switch>
);

export default <Route path="/calendar" component={CalendarRoutes} />;
